import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import {useQuery} from '@apollo/react-hooks';
import {GET_USER_WATCH_SETTINGS_QUERY, ME} from '../../graphql/queries';
import Layout from '../../components/layout/layout';
import Button from '../../components/button';
import PricingPackages from '../../components/pricing/pricing-packages';
import Counter from '../../components/header/counter';
import Section from '../../components/section';
import './styles.scss';
import { getPromoDate } from '../../helpers';

const PricingPromo = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(true);
  const [promo, setPromo] = useState(false);
  // const [firstName, setFirstName] = useState('');
  const [currentPackage, setCurrentPackage] = useState('');
  const [currentPackageInterval, setCurrentPackageInterval] = useState('');
  const [isUser, setIsUser] = useState(false);
  const {data: hexowatchUserInfo} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    navigate('/pricing/');
  }, []);
  useEffect(() => {
    if (
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      hexowatchUserInfo.UserWatchSettings.get.id
    ) {
      setCurrentPackage(hexowatchUserInfo.UserWatchSettings.get.pricing_package);
      setCurrentPackageInterval(hexowatchUserInfo.UserWatchSettings.get.pricing_package_interval);
      setIsUser(true);
    } else if (
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      hexowatchUserInfo.UserWatchSettings.get.id === null
    ) {
      setIsUser(false);
    }
  }, [hexowatchUserInfo]);

  // useEffect(() => {
  // if (userData && userData.User && userData.User.get) {
  //   if (!(userData.User.get.promoData && userData.User.get.promoData.hexowatch_ltd) && !userData.User.get.error) {
  //     navigate('/pricing/');
  //   } else if (userData && userData.User && userData.User.get && userData.User.get.firstName) {
  //     setFirstName(userData.User.get.firstName);
  //   }
  // }
  // }, [userData]);

  // useEffect(() => {
  //   if (!getLTDPromo329()) {
  //     setLTDPromo329('true');
  //   }
  // }, []);

  useEffect(() => {
    if (currentPackageInterval !== 'LTD' && currentPackage !== 'FREE' && isUser) {
      navigate('/pricing/');
    }
  }, [currentPackageInterval, currentPackage, isUser]);

  return (
    <div className="content-wrapper">
      <section className="pc-section-dark">
        {/* <img src={background1} className="holidays-bg-1 d-none d-md-block" alt="" /> */}
        <div className="text-center section-content" style={{maxWidth: '800px', margin: 'auto'}}>
          {/* {firstName ? (
            <div style={{color: '#03B8FF', fontSize: '40px', lineHeight: '42px', fontWeight: 600}}>Hey {firstName}</div>
          ) : (
            <div style={{color: '#03B8FF', fontSize: '40px', lineHeight: '42px', fontWeight: 600}}>Just for you...</div>
          )} */}
          <div
            className="text-center mt-3"
            style={{color: '#fff', fontSize: '30px', fontWeight: 600, marginBottom: '10px', lineHeight: '41px'}}
          >
            You’re invited to our private FLASH PROMO
          </div>
          <div className="text-center" style={{color: '#fff', marginBottom: '0px'}}>
            STAY AHEAD OF YOUR COMPETITION WITH HEXOWATCH
          </div>
          <div className="text-center" style={{color: '#fff', marginBottom: '20px'}}>
            Monitor any website for visual, content, source code, technology, availability, or price changes.
          </div>
          <Counter date={getPromoDate()} light title="DEAL ENDS IN..." titleColor="#fff" titleLarge />
          {/* <img src={products} alt="Holidays 2022" style={{maxWidth: '620px', height: 'auto', width: '100%'}} /> */}
        </div>

        <div className="text-center mt-2">
          <Button className="my-2" onClick={() => navigate('/promo14035/#pricing')}>
            SECURE YOUR LIFETIME DEAL
          </Button>
        </div>
      </section>
      <Layout promoPage>
        <Section className="pricing-section py-lg-5">
          <div id="pricing" className="pricing-section mx-auto mt-5">
            {
              //@ts-ignore
              <PricingPackages
                products={pageContext.products}
                interval={annual ? 'annual' : 'monthly'}
                setAnnual={() => {
                  setAnnual(true);
                }}
                promo={promo}
                LTD
              />
            }
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default PricingPromo;
